import { defineStore } from 'pinia';
import axios from 'axios';
import BelgianChocolate from '@/assets/images/flavor-facts/belgian-chocolate.png';
import ButterPecan from '@/assets/images/flavor-facts/butter-pecan.png';
import DulceDeLeche from '@/assets/images/flavor-facts/dulce-de-leche.png';
import Coffee from '@/assets/images/flavor-facts/coffee.png';
import Chocolate from '@/assets/images/flavor-facts/chocolate.png';
import MintChip from '@/assets/images/flavor-facts/mint-chip.png';
import Vanilla from '@/assets/images/flavor-facts/vanilla.png';
import CookiesAndCream from '@/assets/images/flavor-facts/cookies-and-cream.png';
import ChocolateChipCookieDough from '@/assets/images/flavor-facts/chocolate-chip-cookie-dough.png';
import Strawberry from '@/assets/images/flavor-facts/strawberry.png';

const toMinutes = 1000 /* milliseconds */ * 60 /* seconds */
const cacheExpiration = 90 * toMinutes;

export const useLearningStore = defineStore('learning', {
    state: () => ({
        learningData: null as ParsedSyncData | null,
        videoMapping: videoMappingInitialData(),
        flavorFacts: flavorFactsInitialData(),
    }),
    actions: {
        getLearningData
    }
});

async function getLearningData(this:ReturnType<typeof useLearningStore>) {
    const responseData = await fetch('/api/sync');
    const json = await responseData?.json();
    const processed = processSyncData(json);
    this.learningData = processed;
    
    return processed;
}

export interface FlavorFact {
    name: string,
    description?: string,
    image: string,
    isRbstFree: boolean,
    isKosher: boolean,
    isGmoFree: boolean,
}

export interface SyncData {
    Courses: Course[],
    Groups: Group[],
    Progress: ProgressItem[],
    IsGameInstanceSubmitted: boolean,
    IsNewHighScore: boolean
}

export interface ParsedSyncData extends Omit<SyncData, 'Courses'> {
    Courses: ParsedCourse[],
    Groups: ParsedGroup[],
}

export interface ProgressItem {
    CourseStepId: number,
    UserId: number,
    CourseId: number,
    Status: number,
    CreatedOn: string,
}

export interface Group {
    RecipeGroupId: number,
    Identifier: string,
    Title: string,
    Order: number,
    Disabled: boolean,
    ThumbnailIndex: number,
    ComingSoon: boolean,
    Recipes: Recipe[]
}

export interface ParsedGroup extends Omit<Group, 'Recipes'> {
    Recipes: ParsedRecipe[];
}

export interface Recipe {
    RecipeId: number,
    RecipeGroupId: number,
    Name1: string,
    Name2?: string,
    Type: string,
    Order: number,
    Disabled: boolean,
    ThumbnailIndex: number,
    Instances: RecipeInstance[]
}

export interface ParsedRecipe extends Omit<Recipe, 'Instances'> {
    Instances: ParsedRecipeInstance[];
}

export interface RecipeInstance {
    RecipeInstanceId: number,
    RecipeId: number,
    Identifier: string,
    JsonData: string,
    Order: number,
    Disabled: boolean,
    ImageFile: RecipeImage,
    ImageLastUpdated: string,
    ImageFileSize: number
}

export interface ParsedRecipeInstance extends Omit<RecipeInstance, 'JsonData'> {
    JsonData: ParsedRecipeJson;
}

export interface ParsedRecipeJson {
    size?: number,
    units?: string,
    steps?: string[][],
    points?: string,
    rewards?: string,
    columns?:string,
    rows?: string[][][],
}

export interface RecipeImage {
    OriginalPath: string,
    FullPath: string,
}

export interface Course {
    CourseId: number,
    Identifier: string,
    JsonData: string,
    Order: number,
    Steps: CourseStep[],
    RecipeGroupIds: number[],
    ImageLastUpdated: string,
    VideoLastUpdated: string,
    ImageFileSize: number,
    VideoFileSize: number,
}

export interface ParsedCourse extends Omit<Course, 'JsonData'> {
    JsonData: CourseJsonData;
}

export interface CourseStep {
    CourseStepId: number,
    CourseId: number,
    Type: number,
    ExecuteTime: number,
    JsonData: string,
    DataModel: CourseData,
}

export interface CourseJsonData {
    title: CourseTitle[],
    mobile_title: CourseTitle[],
    note: string,
    ideas?: string|CourseGrid[],
}

export interface CourseTitle {
    text: string,
    font: string,
    size: number,
    baseline: number,
    maximumLineHeight: number,
}

export interface CourseGrid {
    columns: string[],
    rows: string[][],
}

export interface CourseData {
    quizzes?: Quiz[],
    title?: string,
    note?: string,
    image?: string,
    button?: string,
}

export interface Quiz {
    title: string,
    question: string[],
    answers: QuizAnswers[]
}

export interface QuizAnswers {
    text: string,
    correct: boolean,
}

function processSyncData(data:SyncData):ParsedSyncData {
    const sortedGroups = data?.Groups?.sort((a,b) => a.Order - b.Order);
    return {
        ...data,
        Courses: data?.Courses?.map((course:Course):ParsedCourse => {
            const parsed: CourseJsonData = JSON.parse(course.JsonData);
            return {
                ...course,
                JsonData: parsed
            };
        }),
        Groups: sortedGroups?.map((group:Group):ParsedGroup => {
            const sortedRecipes = group?.Recipes?.sort((a,b) => a.Order - b.Order);
            return {
                ...group,
                Recipes: sortedRecipes?.map((recipe:Recipe):ParsedRecipe => {
                    const sortedInstances = recipe?.Instances?.sort((a,b) => a.Order - b.Order);
                    return {
                        ...recipe,
                        Instances: sortedInstances.map((instance:RecipeInstance):ParsedRecipeInstance => {
                            return {
                                ...instance,
                                JsonData: JSON.parse(instance.JsonData.replace(/'/g, '"'))
                            }
                        })
                    }
                })
            }
        }),
    };
}

function videoMappingInitialData() {
    // For Prod only:
    return {
        'a8ae09ed-5912-405c-8377-062bb3ed97ac': '951620706', // product history
        '41369aec-e2fb-4678-a5e8-0836872c204b': '951694750', // sweetness 
        'ebc0fd9a-b3a4-4029-83e0-be8df7b054a6': '1033546665', // loyalty program
        'f92decf7-4861-44e3-bb1c-ce75834a4582': '951694608', // scooping 101
        '948446d9-8abb-4d10-b27c-981bb458875a': '951694578', // dazzler
        '1a7e64a3-ff85-4a39-9f5c-a429ebffaa6e': '1033546195', // spindle
        '21cf4372-b60b-4044-88d4-5aefaf050909': '951694376', // sampling
        'a1996202-00d5-427a-99ff-729546010623': '1033547007', // vitamix
    } as Record<string, string>;
}

function flavorFactsInitialData() {
    return {
        'belgian-chocolate': {
            name: 'belgian chocolate',
            description: 'Our Belgian Chocolate combines rich, velvety chocolate ice cream with finely shaved dark Belgian chocolate for a unique textured experience.',
            image: BelgianChocolate,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: false,
        },
        'butter-pecan': {
            name: 'butter pecan',
            description: 'An American classic, revisited with our passion for singular flavor. We blend spoonful after spoonful of buttery toasted pecans with pure, sweet cream to create a delight like no other.',
            image: ButterPecan,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: false,
        },
        'dulce-de-leche': {
            name: 'dulce de leche',
            description: 'Häagen-Dazs discovered Dulce de Leche in South America in 1998. Our Dulce is inspired by Latin America’s treasured dessert. Our Ducle de Leche ice cream is a delicious combination of caramel and sweet cream, swirled with thick ribbons of golden caramel.',
            image: DulceDeLeche,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: false,
        },
        'coffee': {
            name: 'coffee',
            description: 'We roast the finest Brazilian coffee beans and brew them to perfection to bring out their rich, complex flavor. The combination of the brewed coffee combined with our pure, creamy ice cream awakens the senses. Coffee is one of the original three flavors that began Häagen-Dazs.',
            image: Coffee,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: true,
        },
        'chocolate': {
            name: 'chocolate',
            description: 'One of our original three flavors, our chocolate ice cream is rich, creamy, and totally indulgent. We make it from the finest cocoa and pure, sweet cream. Simple and indulgent.',
            image: Chocolate,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: true,
        },
        'mint-chip': {
            name: 'mint chip',
            description: 'Our Mint Chip isn’t green because we don’t use any green food coloring. Instead, we infuse mint essence into a smooth, creamy base and then we add rich chocolaty chips for the perfect finish to this refreshingly cool treat.',
            image: MintChip,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: false,
        },
        'vanilla': {
            name: 'vanilla',
            description: 'Vanilla is the essence of elegance and sophistication. This marriage of pure, sweet cream and Madagascar vanilla creates the sweet scent of exotic spice.',
            image: Vanilla,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: true,
        },
        'cookies-and-cream': {
            name: 'cookies and cream',
            description: 'Our best-selling ice cream, Cookies and Cream satisfies the kid in all of us. Pieces of rich, chocolaty cookies are dunked in delicious, creamy vanilla ice cream to make our Häagen-Dazs Cookies and Cream.',
            image: CookiesAndCream,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: false,
        },
        'chocolate-chip-cookie-dough': {
            name: 'chocolate chip cookie dough',
            description: 'For something playful, we created an ice cream that reflects the childhood memories of homemade chocolate chip cookies. We blend chunks of buttery cookie dough nd our sweet fudge chips with our finest pure vanilla ice cream.',
            image: ChocolateChipCookieDough,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: false,
        },
        'strawberry': {
            name: 'strawberry',
            description: 'Our strawberries come from farms in the Pacific Northwest that have supplied our berries for over 45 years. It took Rueben Mattus six years to find the perfect berry that satisfied his particular standards. The flavor launched in 1966 and continues to be a fan favorite.',
            image: Strawberry,
            isRbstFree: true,
            isKosher: true,
            isGmoFree: true,
        },
    } as Record<string, FlavorFact>;
}